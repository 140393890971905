import React , {useContext, useState, useEffect, Fragment} from 'react'
import { toast } from 'react-toastify';
import { AuthContext } from '../authentication/AuthProvider';
import Footer from '../layout/Footer';
import { ListItemText, List, ListItem, ListItemIcon,Divider, Button, Typography, Container} from '@mui/material';
import {Apartment,AddCircleOutline, Refresh} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AddBuilding from './AddBuilding';
import Header from '../layout/Header';
import FilterList from '../buttons/FilterList';
import {TailSpin} from "react-loader-spinner";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import useBuildingList from '../commands/useBuildingList';

 function Buildings() 
{
  console.log('Buildings component renders');
  const appInsights = useAppInsightsContext();
  const track = useTrackEvent(appInsights, "ManagementApp");
  const { isAdmin} = useContext(AuthContext);
  const [showAddBuilding, setShowAddBuilding] = useState(false);

  const {buildingsList, fetchBuildingsList } = useBuildingList();
  const [filteredList, setFilteredList] = useState(null);

  const navigate = useNavigate();
  
  const handleBuildingClick = (e, buildingid) => {
      navigate(`/building/`+buildingid);
  };

    // exectue when buildingsList is upadated (when hook completes)
    useEffect(() => {
      if (buildingsList !== null && buildingsList !== undefined) {
        setFilteredList(buildingsList);
       }
      // //if there is only one building in the list, navigate to the building page, unless the user is an admin (to allow adding first building)
      //     if(!isAdmin && buildingsList !== null && buildingsList !== undefined && buildingsList.length===1){
      //         navigate(`/building/`+buildingsList[0].id);
      //       }
      //   }

      }, [buildingsList]);
   
    const addCancel = () => {
      setShowAddBuilding(false);
    };
  
    const addSuccess = () => {  
      setShowAddBuilding(false);
      toast.success('הוספת בניין בוצעה בהצלחה!');
      refreshList();
    };

    const addFailure = () => {  
      setShowAddBuilding(false);
      toast.error('הוספת בניין נכשלה!');
    };
    
    const handleAddBuildingClick = () => {
      setShowAddBuilding(true);
    };
    
    const dataFiltered = (filtered) => {
       console.log('filtered list: ' + JSON.stringify(filtered));  
       setFilteredList(filtered);
    }

    const refreshList = () => {
      fetchBuildingsList(true); // Force refresh
    };

return (
  <Fragment> 
  <Header/>
  <Container maxWidth="md" sx={{pt:2, maxHeight:'86vh'}}>
    {filteredList && filteredList.length>0? 
    <div className='d-flex flex-column'>
      {!showAddBuilding &&
      <Fragment>
        <List 
        sx={{
          pt: 0,
          bgcolor: 'background.paper',
          boxShadow: 3,
          overflowY: 'auto'
        }}>
          <ListItem sx={{backgroundColor:"grey.200"}} key="header1">
          <div className="d-flex flex-row justify-content-center align-items-center w-100">
            <Typography variant='h4' color="GrayText">רשימת הבניינים</Typography>
          </div>
          </ListItem>
          <ListItem alignItems='flex-start' key="header2">
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <FilterList data={buildingsList} onFilter={dataFiltered} fields="['title', 'address', 'city']"/>
              </div>
          </ListItem>
          <ListItem key="button">
            <ListItemText sx={{textAlign:"start"}}>
              {isAdmin &&
                  <Button variant="contained" size="medium" startIcon={<AddCircleOutline  />} onClick={()=>handleAddBuildingClick()} > 
                    &nbsp;הוספת בניין&nbsp;
                  </Button>
              }
            </ListItemText>
            <ListItemText sx={{textAlign:"end"}}>
              <Button variant="contained" size="medium" startIcon={<Refresh />} onClick={refreshList} >&nbsp; רענון רשימה &nbsp;</Button>
            </ListItemText>
            </ListItem>

          <ListItem key="header3">
            <ListItemText sx={{textAlign:"start"}}>&nbsp;</ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold'}} ><Typography  fontWeight="bold" >שם בניין</Typography></ListItemText>
            <ListItemText sx={{textAlign:"start",fontWeight: 'bold'}}><Typography fontWeight="bold" >כתובת</Typography></ListItemText>
            < ListItemText sx={{textAlign:"start",fontWeight: 'bold'}}><Typography fontWeight="bold" >עיר</Typography></ListItemText>
          </ListItem>
          </List>
          <List 
        sx={{
          pt: 0,
          bgcolor: 'background.paper',
          boxShadow: 3,
          mb:1,
          maxHeight: '100%',
          overflowY: 'auto'

        }}>
          {filteredList && Array.isArray (filteredList) && filteredList.map((building) => (
            <div key={building.id}>
              <Divider variant="fullwidth" component="li" />
              <ListItem alignItems='flex-start' key={building.id} onClick={(e)=>{handleBuildingClick(e,building.id)}}
              sx={{
                '&:hover': {
                  backgroundColor: 'grey.200' // change this to your preferred color
                },
              }}>
                <ListItemIcon>
                        <Apartment />
                </ListItemIcon>
                <ListItemText sx={{textAlign:"start"}} id={building.id} primary={building.title}  />
                <ListItemText sx={{textAlign:"start"}} id={building.id}  secondary={building.fullAddress.address} />
                <ListItemText  sx={{textAlign:"start"}} id={building.id}  secondary={building.fullAddress.city} />
              </ListItem>
            </div>
              ))}

        </List>
    </Fragment>}
    {
      showAddBuilding && <AddBuilding onCancel={addCancel} onFailure={addFailure} onSuccess={addSuccess}/>
    }
    <Footer/>
    </div>
      : (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80vh', alignItems:'center'}}>
          <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
        </div>
      )}
      
  </Container>
  </Fragment>
  );
}
export default Buildings;