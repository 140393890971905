// BuildingInfo.js

import React, { useState, useEffect, useContext, Fragment } from 'react';
import { AuthContext } from '../authentication/AuthProvider';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useBuildingData from '../commands/useBuildingData';
import Header from '../layout/Header';
import BuildingHeader from '../building/BuildingHeader';
import { Button, TextField, Typography,Grid,ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { Apartment, Cancel, Edit, Send, SmartScreen, PhoneIphone, AddCircle, VerifiedUser,ShieldOutlined } from '@mui/icons-material';
import AddDevice from '../device/AddDevice';
import AddKey from '../device/AddKey';
import { useNavigate } from 'react-router-dom';
import {TailSpin} from "react-loader-spinner";
import Confirmation from '../inputs/Confirmation';
import Footer from '../layout/Footer';

export default function FlatInfo() {
  
  const [flatData, setFlatData] = useState(null);
  const {buildingId, flatId} = useParams();
  const [editMode, setEditMode] = useState(false);
  const [deleteButtonText, setDeleteButtonText] = useState('מחק דירה');
  const [editButtonText, setEditButtonText] = useState('עדכון');
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [deviceType, setDeviceType] = useState('monitor'); //monitor or phone
  const [showAddKey, setShowAddKey] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { buildingData, fetchBuildingData } = useBuildingData(buildingId);

  //flat properies
  const [allowMultiDevice, setAllowMultiDevice] = useState(false);
  const [allowAddPhone, setAllowAddPhone] = useState(false);
  const [tagCount, setTagCount] = useState(0); //number of tags in the flat
  const [callMode, setCallMode] = useState('individual');
  const [familyName, setFamilyName] = useState('');
  const [flatName, setFlatName] = useState('');
  const [maxNumOfPhones, setMaxNumOfPhones] = useState(5); //default value

  const { requestHeaders, isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    //check if query string contains refreshFlag=true
    const urlParams = new URLSearchParams(window.location.search);
    const refreshFlag = urlParams.get('refreshFlag');
    if (refreshFlag === 'true') {
      console.log('refreshFlag found, refreshing building data');
      refreshData();
      urlParams.delete('refreshFlag');
    }
  }, []);

  useEffect(() => {
    setFlatDataFromBuildingData(buildingData);
  }, [buildingData]);

  const openAddDialog = (deviceType) =>{
    if(deviceType === 'nfc')
      {
        setShowAddKey(true);
        return;
      }
      else
      {
        setDeviceType(deviceType);
        setShowAddDevice(true);
      }
  }

  const setFlatDataFromBuildingData = (_buildingData) => {
    if (!_buildingData || !_buildingData.flats) {
      return;
    }
    console.log('_buildingData.flats', _buildingData.flats);
    const flat = _buildingData.flats.find(flat =>
        {
            console.log('flat.flatid:', flat.flatid, typeof (flat.flatid));
            return (typeof flat.flatid === "number" ? flat.flatid.toString() : flat.flatid) === flatId;
        }  
    );
    setFlatData(flat);
    setFamilyName(flat.familyName);
    setFlatName(flat.flatname);
    setCallMode(flat.callMode ? String(flat.callMode) : 'individual'); // or 'all'
    setAllowMultiDevice(flat.allowMonitorAndPhones);
    if(flat.maxNumOfPhones){
      setMaxNumOfPhones(flat.maxNumOfPhones);
    }
    console.log('flat:', flat);
  };

  useEffect(() => {
    // Check if buildingData is available and flatData is not set
    setFlatDataFromBuildingData(buildingData);
    isAddingPhoneAllowed();
    setTagCount(flatData  && flatData.keys ? flatData.keys.length : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatData]);
  
  const isAddingPhoneAllowed = () => {
    if (!flatData) {
      return false;
    }
    const monitorsExist = flatData.devices.filter(device => device.devicetype === 'monitor').length > 0;
    const phoneCount = flatData.devices.filter(device => device.devicetype === 'phone').length;
    console.log('monitorsExist:', monitorsExist);
    console.log('phoneCount:', phoneCount);
    //if flat has 5 phones (or more), don't allow adding any more
    if(phoneCount>=maxNumOfPhones ){
      setAllowAddPhone(false);
      return;
    }
    else{
      if(!monitorsExist){
        setAllowAddPhone(true);
        return;
      }
      if(monitorsExist && (allowMultiDevice || isAdmin)){
        setAllowAddPhone(true);
        return;
      }

    }
    setAllowAddPhone((!monitorsExist && phoneCount<=5) || (isAdmin && allowMultiDevice));
    console.log('allowAddPhone:', allowAddPhone);
  }
  
  const updateFlat = async () => {
    const targetServer = process.env.REACT_APP_API_HOST;
    const api = `api/building/${buildingId}/${flatId}`;
    
    let updatePayload = {};
    if (familyName  && familyName !== flatData.familyName) {
      updatePayload.familyName = familyName;
    }
    if (flatName && flatName !== flatData.flatname) {
      updatePayload.flatname = flatName;
    }
    if(callMode !== flatData.callMode){
      updatePayload.callMode = callMode;
    }
    updatePayload.allowMultiDevice = allowMultiDevice;
    updatePayload.maxNumOfPhones =Math.floor(maxNumOfPhones); //use Math.floor to make sure the value is an integer

    console.info('Update flat called with flat ', updatePayload);
    try {
      const response = await fetch(targetServer + api, {
        method: 'PUT',
        headers: requestHeaders,
        body: JSON.stringify(updatePayload),
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Flat updated successfully! Response:', data);
        toast.success('דירה עודכנה בהצלחה');
        refreshData();
        setEditMode(false);
      }
      else if (!response.ok) {
        const errorData = await response.text();
        console.error('Failed to update flat! Error:', errorData);
        toast.error(`בעיה בעדכון הדירה:  ${errorData}`);
        setEditMode(false);
      }
  }
  catch (error) {
    toast.error('בעיה בעדכון הדירה: ', error);
    console.error(error);
    setEditMode(false);
  }
};

const  enterEditMode = () => {
  if(editMode){
      updateFlat();
      exitEditMode();
  }
  else{
      setEditMode(true);
      setDeleteButtonText("ביטול");
      setEditButtonText("שמירה");
  }
}

const exitEditMode=()=>{
  setEditMode(false);
  setDeleteButtonText("מחיקה");
  setEditButtonText("עדכון");
}

const resetEditFields = () => {
  setFamilyName(flatData.familyName);
  setFlatName(flatData.flatname);
  setCallMode(flatData.callMode ? String(flatData.callMode) : 'individual');
  setAllowMultiDevice(flatData.allowMonitorAndPhones);
  setMaxNumOfPhones(flatData.maxNumOfPhones);
}

const confirmDelete = () => {
  //if in edit mode, cancel edit mode
  if(editMode){
      resetEditFields();
     exitEditMode();
  }
}

const refreshData = () => {
  fetchBuildingData(buildingId, true);
}

const allowMultiDEviceChanged = (e) => {
    setAllowMultiDevice(e.target.value==='true');
}

const callModeChanged = (e) => {
  setCallMode(e.target.value); //update the call mode
  setShowConfirm(true);// show confirmation dialog; if the user clicks cancel setCallMode value will get reversed
}
const cancelCallModeChange = () => {
  if(callMode==='all'){
    setCallMode('individual');
  }
  else
{
    setCallMode('all');
}
  setShowConfirm(false);
}

const handleDeviceClick = (e, deviceId) => {
  navigate(`/building/${buildingId}/${flatId}/${deviceId}`);
};  

const updateMaxNumOfPhones = (e) => {
  setMaxNumOfPhones(e.target.value);
}

  return (
    <Fragment>
    <Header/>
    <BuildingHeader buildingData={buildingData} buildingId={buildingId} refreshData={() => {refreshData();}}/>
    {buildingData ? 
    <Fragment>
      {flatData ?
      <Fragment>
        <Grid container spacing={2} maxWidth="md" justifyContent="center" alignItems="center"
            sx={{
              pt: 0,
              bgcolor: 'background.paper',
              boxShadow: 3,
              margin: '0 auto', // Center the grid container itself
              mt: '10px',
              mb:'10px',
              pb:2,
              maxWidth: {xs: '90%', sm:'40%'},
            }}
          >
            <Grid item xs={4} sx={{backgroundColor: 'grey.200',display: 'flex',  padding:3, justifyContent:'center'}} >
              <Apartment/>
            </Grid>
            <Grid item xs={8} sx={{backgroundColor: 'grey.200',display: 'flex', pb:2 }} >
              <Typography variant="h6" align="center">פרטי דירה {flatData.flatname}</Typography>
            </Grid>
            <Grid item xs={4}> 
                <Typography>שם משפחה</Typography>
            </Grid>
            <Grid item xs={8}>
              {!editMode && flatData.familyName}
              {editMode && <TextField id="familyName" variant="standard" value={familyName} onChange={(e) => setFamilyName(e.target.value)}  sx={{ width: '150px' }}/>}
            </Grid>
            <Grid item xs={4}> 
                <Typography>מצב קריאה</Typography>
            </Grid>
            <Grid item xs={8}>
              {!editMode && (callMode==='all'?'משפחתי':'יחידני')}
              {editMode &&
                <ToggleButtonGroup
                dir='ltr'
                value={callMode}
                exclusive
                onChange={callModeChanged}
                aria-label="call mode"
              >
                <ToggleButton value="individual" aria-label="individual">
                  יחידני
                </ToggleButton>
                <ToggleButton value="all" aria-label="all">
                  משפחתי
                </ToggleButton>
              </ToggleButtonGroup>
              }
            </Grid>
            <Grid item xs={4}> 
                <Typography> טלפון + מוניטור</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{allowMultiDevice}</Typography>
              {!editMode && isAdmin && (allowMultiDevice?'כן':'לא') }
              {editMode && isAdmin && 
                <ToggleButtonGroup
                dir='ltr'
                value={allowMultiDevice}
                exclusive
                onChange={allowMultiDEviceChanged}
                aria-label="allow multi device"
              >
                <ToggleButton value={false} aria-label="no">לא</ToggleButton>
                <ToggleButton value={true} aria-label="yes">כן</ToggleButton>
              </ToggleButtonGroup>
                }

            </Grid>
      
            <Grid item xs={4}> 
                <Typography> מספר טלפונים</Typography>
            </Grid>
            <Grid item xs={8}>
              {!editMode && isAdmin && maxNumOfPhones}
              {editMode && isAdmin && 
                  <input type="number" min="5" max="10" onInput={(e)=>{updateMaxNumOfPhones(e)}} value={maxNumOfPhones}/>
                }
            </Grid>
            <Grid item xs={4}> 
                <Typography> NFC</Typography>
            </Grid>
            <Grid item xs={8}>
              {isAdmin && 
                <Button variant="contained" size="medium" onClick={()=>openAddDialog("nfc")} sx={{marginX:'10px'}}><AddCircle /></Button>
              }
              {tagCount}
            </Grid>
            <Grid item xs={12}>
            {isAdmin &&
            <Fragment>
            {editMode && 
              <Button variant="contained" size="medium" startIcon={<Cancel />} sx={{ width: '120px', margin:'10px'}} onClick={confirmDelete}>
                &nbsp; {deleteButtonText} &nbsp;
              </Button>}
              <Button variant="contained" size="medium" startIcon={editMode?<Send />:<Edit  />}  sx={{ width: '120px', margin:'10px' }} onClick={enterEditMode}> 
                &nbsp; {editButtonText} &nbsp;
              </Button> 
              </Fragment>
          }
            </Grid>
        </Grid>
        <Grid container spacing={2} maxWidth="md" justifyContent="center" alignItems="center"
            sx={{
              pt: 0,
              bgcolor: 'background.paper',
              boxShadow: 3,
              margin: '0 auto', // Center the grid container itself
              mb:'30px',
              pb:2,
              maxWidth: {xs:'90%', sm: '40%'},
            }}
          >
                        <Grid item xs={12} sx={{backgroundColor: 'grey.200',display: 'flex', alignItems: 'right', pb:2, justifyContent:"center" }}  >
              <Typography variant='h6' align="center">דיירים ומכשירים</Typography>
            </Grid>

              {flatData.devices.map((device) => (
                <Box 
                    key={device.deviceid}
                    onClick={(e)=>{handleDeviceClick(e,device.deviceid)}}
                    sx={{ cursor: 'pointer', display: 'flex', width: '100%', '&:hover': { backgroundColor: 'grey.200' } }}
                  >
                <Grid item xs={4}>
                  {device.devicetype === 'monitor' ? <SmartScreen /> : < PhoneIphone/>}
                </Grid> 
                <Grid item xs={6}>
                  {device.displayName}
                </Grid>
                <Grid item xs={2}>
                  {device.devicetype === 'phone' && device.isAuthenticated && <VerifiedUser/>}
                  {device.devicetype === 'phone' && !device.isAuthenticated && <ShieldOutlined/>}
                </Grid>
              </Box>
            ))}
            <Grid item xs={12}>
            {isAdmin &&
        <Button variant="contained" size="medium" startIcon={<AddCircle />} onClick={()=>openAddDialog("monitor")} sx={{ width: '120px', marginX:'10px' }}>&nbsp; מכשיר&nbsp;
        </Button>
      }
      {allowAddPhone &&
        <Button variant="contained" size="medium" startIcon={<AddCircle />} onClick={()=>openAddDialog("phone")} sx={{ width: '120px', marginX:'10px' }}>&nbsp; דייר&nbsp;
        </Button>
      }
      </Grid>
          </Grid>
      <AddDevice show={showAddDevice} buildingId={buildingId} flatId={flatId}  onAddDevice={()=>{refreshData(); setShowAddDevice(false)}} onComplete={()=>setShowAddDevice(false)} deviceType={deviceType}/>
      <AddKey show={showAddKey} buildingId={buildingId} flatId={flatId} onAddKey={()=>refreshData()} onComplete={()=>setShowAddKey(false)}/>
      {showConfirm && <Confirmation title="זהירות!" content="שינוי מצב דירה דורש סגירה ופתיחה מחדש של האפליקציה בכל מכשיר, האם להמשיך?" onConfirm={()=>setShowConfirm(false)} onCancel={()=>cancelCallModeChange()}/>}
    </Fragment>
      :
      <h2>דירה לא ידועה</h2>
     }
    </Fragment>
    : 
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '80vh', alignItems:'center'}}>
      <TailSpin type="TailSpin" color="#00BFFF" height={80} width={80} />
    </div>

    }
    <Footer />

    </Fragment>
    )
  
}

